<template>
  <v-container v-if="recipe" class="wp-page recipe-detail" @click="clicked">
    <breadcrumb :items="breadcrumb" />
    <v-row>
      <v-col cols="12">
        <v-img
          class="mb-3 rounded"
          :src="recipe.featured_image_url"
          :alt="`ricetta-${recipe.slug}`"
        />
        <h1 class="my-6" v-html="recipe.title.rendered"></h1>
      </v-col>
      <v-col cols="12">
        <v-row>
          <v-col cols="12" md="6" class="recipe-info pa-4">
            <v-chip
              v-if="recipe.course && recipe.course.length"
              color="primary"
              class=" font-weight-bold text-uppercase mb-n4"
              >{{ recipe.course }}</v-chip
            >
            <h2 class="py-4">{{ $t("recipes.description") }}</h2>
            <div v-html="recipe.content.unrendered"></div>
            <v-divider></v-divider>
            <div class="recipe-instructions">
              <h2 class="py-4">{{ $t("recipes.steps") }}</h2>
              <ol>
                <li
                  v-for="(instruction, idx) in recipe.recipe_instructions[0]"
                  :key="idx"
                  v-html="instruction.description"
                  class="mb-3"
                ></li>
              </ol>
            </div>
          </v-col>
          <v-col cols="12" md="6" class="recipe-ingrediente pa-4">
            <div v-if="ingredients && ingredients.length">
              <v-list
                v-if="recipe.recipe_ingredients"
                class=" w-100 rounded-md"
              >
                <h2>
                  {{ $t("recipes.ingredients.title") }}
                </h2>
                <v-list-item
                  v-for="(ingredient, index) in ingredients"
                  :key="ingredient.ingredient_id"
                  class="ingrediets-list px-0"
                >
                  <v-list-item-content>
                    <IngredientCard v-bind:product="ingredient" />
                    <v-divider
                      v-if="index < ingredients.length - 1"
                    ></v-divider>
                  </v-list-item-content>
                </v-list-item>
              </v-list>
              <v-row class="mt-3 d-flex justify-space-between">
                <v-col cols="12" sm="6">
                  <v-btn
                    outlined
                    color="secondary"
                    class="w-100 recipe-btn bg-white"
                    x-large
                    elevation="0"
                    @click="addAllToFavorites"
                  >
                    {{ $t("recipes.addItem") }}
                  </v-btn>
                </v-col>
                <v-col cols="12" sm="6">
                  <v-btn
                    elevation="0"
                    color="primary"
                    class="w-100 recipe-btn"
                    x-large
                    @click="addAllToCart"
                  >
                    {{ $t("recipes.addToCart") }}
                  </v-btn>
                </v-col>
              </v-row>
            </div>
            <div v-else><h2>Nessun ingrediente</h2></div>
          </v-col>
        </v-row>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <router-link
          class="d-flex align-center recipe-detail-go-back my-4"
          :to="{
            name: routeName
          }"
        >
          <v-icon color="primary" class="pr-2">$chevronLeft</v-icon>
          <span class="font-weight-bold text-uppercase primary--text">{{
            $t("recipes.goBack")
          }}</span></router-link
        >
      </v-col>
    </v-row>
  </v-container>
</template>
<style lang="scss">
@import url("/p/wp-content/themes/abbondanza/styles-common.css?ver=wp");
.recipe-detail {
  .recipe-info {
    background: var(--v-grey-lighten1);
  }
  &-go-back {
    text-decoration: none;
  }
  img {
    width: 100%;
    height: auto;
  }
  iframe {
    width: 100%;
    aspect-ratio: 16/9;
  }
  &-ingredients {
    .v-list {
      border: 1px solid var(--v-accent-base);
    }
  }
}
</style>
<script>
import IngredientCard from "@/components/product/IngredientsCard.vue";
import ListSelector from "@/components/lists/ListSelector.vue";
import loadURL from "~/mixins/loadURL";
import clickHandler from "~/mixins/clickHandler";
import categoryMixins from "~/mixins/category";
import productMixin from "~/mixins/product";
import login from "~/mixins/login";
import CMService from "~/service/cmService";
import ProductService from "~/service/productService";
import CartService from "~/service/cartService";
import ListService from "~/service/listService";
import { mapActions } from "vuex";
import Breadcrumb from "@/components/navigation/Breadcrumb.vue";
import page from "~/mixins/page";

export default {
  name: "RecipeDetail",
  mixins: [clickHandler, categoryMixins, productMixin, login, loadURL, page],
  components: {
    IngredientCard,
    Breadcrumb
  },
  jsonld() {
    if (this.recipe) {
      const ingredients = this.recipe.recipe_ingredients[0].map(ingredient =>
        `${ingredient.amount} ${ingredient.unit} di ${ingredient.ingredient}`.trim()
      );

      // const recipeTimeTextMap = {
      //   min: "M",
      //   mins: "M",
      //   minute: "M",
      //   minutes: "M",
      //   minuti: "M",
      //   hour: "H",
      //   hours: "H",
      //   ora: "H",
      //   ore: "H"
      // };

      // const formattedPrepTimeText =
      //   recipeTimeTextMap[this.recipe.recipe_prep_time_text.toLowerCase()];

      // const formattedCookTimeText =
      //   recipeTimeTextMap[this.recipe.recipe_cook_time_text.toLowerCase()];

      // const prepTime = `PT${this.recipe.recipe_prep_time}${formattedPrepTimeText}`;
      // const cookTime = `PT${this.recipe.recipe_cook_time}${formattedCookTimeText}`;

      // const totalTime = `PT${this.$dayjs
      //   .duration(prepTime)
      //   .add(this.$dayjs.duration(cookTime))
      //   .toISOString()}`;

      const recipeInstructions = this.recipe.recipe_instructions[0];

      const recipeUrl =
        "https://qa.galasupermercatimobile.it/ricette/" + this.recipe.slug;

      const transformedInstructions = recipeInstructions.map(instruction => {
        return {
          "@type": "HowToStep",
          name: instruction.group,
          text: instruction.description,
          url: recipeUrl,
          image: instruction.image
        };
      });

      return {
        "@context": "https://schema.org/",
        "@type": "Recipe",
        name: this.recipe.title.rendered,
        image: this.recipe.featured_image_url,
        author: {
          "@type": "Organization",
          name: "Gala"
        },
        datePublished: this.$dayjs(this.recipe.date).format("YYYY-MM-DD"),
        description: this.recipe.raw_excerpt,
        // prepTime: prepTime,
        // cookTime: cookTime,
        // totalTime: totalTime,
        keywords: this.recipe.tags_name.join(","),
        recipeCategory: this.recipe.course,
        recipeCuisine: this.recipe.cuisine,
        recipeIngredient: ingredients,
        recipeInstructions: transformedInstructions,
        aggregateRating: {
          "@type": "AggregateRating",
          ratingValue: this.recipe.stars
        },
        video: {
          "@type": "VideoObject",
          name: this.recipe.title.rendered,
          description: this.recipe.raw_excerpt,
          thumbnailUrl: this.recipe.featured_image_url,
          embedUrl: this.recipe.video_url
        }
      };
    }
  },
  data() {
    return {
      content: null,
      recipe: null,
      products: null,
      ingredients: [],
      items: []
    };
  },

  mounted() {
    this.fetchRecipe(this.$route.params.postName);
  },
  methods: {
    ...mapActions({
      addProduct: "cart/addProduct"
    }),
    async fetchRecipe(postName) {
      const res = await CMService.getCustomPostByFilters("recipe", [
        {
          filterName: "filter[recipe]",
          filterValue: postName
        }
      ]);

      if (res && res.data && res.data.length) {
        this.recipe = res.data[0];
        const wpIngredients = this.recipe.recipe_ingredients[0];
        const ids = wpIngredients.map(product => Number(product.notes));
        const ingredients = await ProductService.getProductsByIds(ids);
        this.products = ingredients.products;
        if (this.products) {
          for (const ingredient of wpIngredients) {
            let objIngr = ingredients.products.find(
              ({ productId }) => productId == ingredient.notes
            );
            if (objIngr) {
              objIngr.existsInShop = true;
              const quantity = CartService.plus(objIngr, 0, false);
              this.items.push({ product: objIngr, quantity });
            } else {
              objIngr = {};
              objIngr.existsInShop = false;
            }

            objIngr.wp_amount = ingredient.amount;
            objIngr.wp_unit = ingredient.unit;
            objIngr.wp_ingredient = ingredient.ingredient;
            this.ingredients.push(objIngr);
          }
        }
      }
    },
    async addAllToCart() {
      // let qty;
      if (await this.needLogin("addtocart")) {
        if (await this.needAddress()) {
          if (await this.needTimeslot()) {
            for (const product of this.products) {
              // if (product.productInfos.TIPOLOGIA === "Sfuso") {
              //   qty = product.productInfos.STEP_SELLING;
              // } else {
              //   qty =
              //     product.productInfos.QTY_MULT || product.productInfos.MINACQ;
              // }
              const quantity = CartService.plus(product, 0, false);
              await this.addProduct({ product, quantity });
            }
          }
        }
      }
    },
    async addAllToFavorites() {
      if (await this.needLogin("addtocart")) {
        let vm = this;
        let res = await vm.$dialog.show(ListSelector, {
          waitForResult: true,
          fullscreen: vm.$vuetify.breakpoint.smAndDown,
          width: 300
        });
        // let newQuantity = CartService.plus(vm.product, vm.quantity);
        if (res) {
          await ListService.addProductsToList(res.listId, this.items);
        }
      }
    }
  },
  computed: {
    routeName() {
      if (this.recipe.type === "recipe") {
        if (this.recipe.cuisine === "Appuntino") {
          return "AppuntinoList";
        } else if (this.recipe.cuisine === "Mixology") {
          return "MixologyList";
        }
      }
      return "RecipeList";
    }
  },
  watch: {
    "$route.params.postName"() {
      this.fetchRecipe(this.$route.params.postName);
    }
  },
  metaInfo() {
    if (this.recipe) {
      return {
        title: this.recipe.title.rendered + " - Gala",
        link: [
          {
            rel: "canonical",
            href: `https://${window.location.host}${this.$route.path}`
          }
        ],
        meta: [
          {
            vmid: "description",
            name: "description",
            content: this.recipe.title.rendered + " - Gala"
          },
          // Open Graph Metas
          {
            property: "og:locale",
            content: "it_IT"
          },
          {
            property: "og:title",
            content: this.recipe.title.rendered + " - Gala"
          },
          {
            property: "og:type",
            content: "website"
          },
          {
            property: "og:url",
            content: location.href
          },
          {
            property: "og:image",
            content:
              "https://" + window.location.host + global.config.socialLogo ||
              "/logo/social-logo.png"
          },
          {
            property: "og:image:alt",
            content: this.recipe.title.rendered + " - Gala"
          },
          {
            property: "og:site_name",
            content: global.config?.defaultPageName
          },
          // Twitter Metas
          {
            name: "twitter:card",
            content: "summary_large_image"
          },
          {
            name: "twitter:title",
            content: this.recipe.title.rendered + " - Gala"
          },
          {
            name: "twitter:image",
            content:
              "https://" + window.location.host + global.config.socialLogo ||
              "/logo/social-logo.png"
          },
          {
            name: "twitter:url",
            content: location.href
          }
        ]
      };
    }
  }
};
</script>
